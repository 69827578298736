import React, { useState } from 'react';

function LeapYearChecker() {
  const [year, setYear] = useState('');
  const [isLeapYear, setIsLeapYear] = useState(null);

  const checkLeapYear = () => {
    // Convert the input value to a number
    const yearNumber = parseInt(year);

    // Check if the input is a valid number
    if (isNaN(yearNumber)) {
      alert('Please enter a valid year.');
      return;
    }

    // Leap years are divisible by 4
    // However, years divisible by 100 are not leap years, unless they are also divisible by 400
    if ((yearNumber % 4 === 0 && yearNumber % 100 !== 0) || yearNumber % 400 === 0) {
      setIsLeapYear(true); // It's a leap year
    } else {
      setIsLeapYear(false); // It's not a leap year
    }
  };

  return (
    <div>
      <h1>Leap Year Checker</h1>
      <p>Enter a year:</p>
      <input type="text" value={year} onChange={(e) => setYear(e.target.value)} />
      <p>
      </p>
      <button onClick={checkLeapYear}>Check Leap Year</button>
      <p>
        {isLeapYear === true && `${year} is a leap year.`}
        {isLeapYear === false && `${year} is not a leap year.`}
      </p>
    </div>
  );
}

export default LeapYearChecker;
