import React, { useState } from 'react';


function StringReversal() {
  const [inputString, setInputString] = useState('');
  const [reversedString, setReversedString] = useState('');

  // Function to reverse a string
  const reverseString = (str) => {
    return str.split('').reverse().join('');
  };

  // Function to handle reversal and display
  const reverseAndDisplay = () => {
    // Reverse the input string
    const reversed = reverseString(inputString);
    // Set the reversed string
    setReversedString(reversed);
  };

  return (
    <div className="App">
      <h1>String Reversal</h1>
      <textarea
        id="inputString"
        value={inputString}
        placeholder="Enter a string..."
        onChange={(e) => setInputString(e.target.value)}
      ></textarea>
      <br />
      <button onClick={reverseAndDisplay}>Reverse</button>
      <br />
      <textarea
        id="outputString"
        value={reversedString}
        placeholder="Reversed string..."
        readOnly
      ></textarea>
    </div>
  );
}

export default StringReversal;
