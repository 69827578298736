import React, { useState } from 'react';

function RemoveDuplicatesFromArray() {
  const [inputArray, setInputArray] = useState('');
  const [result, setResult] = useState('');

  const removeDuplicatesFromArray = () => {
    // Convert the input value to an array
    const arr = inputArray.split(",").map(item => item.trim());

    // Create an empty object to store unique elements
    const uniqueElements = {};

    // Iterate through the input array
    for (let i = 0; i < arr.length; i++) {
      // Use each element as a key in the object
      // This automatically removes duplicates because object keys must be unique
      uniqueElements[arr[i]] = true;
    }

    // Iterate through the keys of the object and push them into the result array
    const uniqueArray = Object.keys(uniqueElements);

    // Display the result
    setResult("Array with duplicates removed: " + uniqueArray.join(", "));
  };

  return (
    <div>
      <h1>Remove Duplicates from Array</h1>
      <p>Enter elements separated by commas:</p>
      <input
        type="text"
        value={inputArray}
        onChange={(e) => setInputArray(e.target.value)}
      />
          <p>
      </p>
      <button onClick={removeDuplicatesFromArray}>Remove Duplicates</button>
      <p>{result}</p>
    </div>
  );
}

export default RemoveDuplicatesFromArray;
