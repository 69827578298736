import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import StringReversal from './ex/Ex1';
import SumOfPositiveNumbers from './ex/Ex2';
import FactorialCalculator from './ex/Ex3';
import VowelCounter from './ex/Ex4';
import MaximumNumberFinder from './ex/Ex5';
import PalindromeChecker from './ex/Ex6';
import LongestWordFinder from './ex/Ex7';
import RemoveDuplicatesFromArray from './ex/Ex8';
import LeapYearChecker from './ex/Ex9';
import DataConverter from './ex/Ex10';
import BinarySearchSorter from './ex/Ex11';
import CronJobGenerator from './ex/Ex12';
import RomanNumeralConverter from './ex/Ex13';
import MorseCodeConvertor from './ex/Ex14';
import Base64Converter from './ex/Ex15';
import VigenereCipher from './Ex16';

function App() {
  const [activeComponent, setActiveComponent] = useState('');
  const vantaRef = useRef(null); // Create a ref for the target element

  useEffect(() => {
    const initVanta = () => {
      if (window.VANTA && vantaRef.current) {
        window.VANTA.NET({
          el: vantaRef.current,
          mouseControls: false,
          touchControls: true,
          gyroControls: false,
          minHeight: 1280.00,
          minWidth: 878.00,
          scale: 1.00,
          scaleMobile: 1.00
        });
      }
    };

    const loadScripts = async () => {
      const threeScript = document.createElement('script');
      threeScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js';
      threeScript.async = true;
      document.body.appendChild(threeScript);

      const vantaScript = document.createElement('script');
      vantaScript.src = 'https://cdn.jsdelivr.net/npm/vanta@latest/dist/vanta.net.min.js';
      vantaScript.async = true;
      document.body.appendChild(vantaScript);

      return new Promise((resolve) => {
        vantaScript.onload = () => resolve();
      });
    };

    loadScripts().then(() => {
      initVanta();
    });

    return () => {
      if (window.VANTA) {
        window.VANTA.NET().destroy();
      }
    };
  }, []);

  const handleClick = (componentName, e) => {
    e.preventDefault();
    setActiveComponent(componentName);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'StringReversal':
        return <StringReversal />;
      case 'SumOfPositiveNumbers':
        return <SumOfPositiveNumbers />;
      case 'FactorialCalculator':
        return <FactorialCalculator />;
      case 'VowelCounter':
        return <VowelCounter />;
      case 'MaximumNumberFinder':
        return <MaximumNumberFinder />;
      case 'PalindromeChecker':
        return <PalindromeChecker />;
      case 'LongestWordFinder':
        return <LongestWordFinder />;
      case 'RemoveDuplicatesFromArray':
        return <RemoveDuplicatesFromArray />;
      case 'LeapYearChecker':
        return <LeapYearChecker />;
      case 'DataConvertor':
        return <DataConverter />;
      case 'BinarySearchSorter':
        return <BinarySearchSorter />;
      case 'CronJobGenerator':
        return <CronJobGenerator />;
      case 'RomanNumeralConverter':
        return <RomanNumeralConverter />;
        case 'MorseCodeConvertor':
      return <MorseCodeConvertor />;
        case 'Base64Converter':
      return <Base64Converter />;
        case 'VigenereCipher':
      return <VigenereCipher />;
      default:
        
        return null;
    }
  }; 

  return (
    <div className="App" >
      <header className="App-header" ref={vantaRef} >
        React Code Challenge
        <img src={logo} className="App-logo" alt="logo" />   
        <div className="container">
        {renderComponent()}
        <button className="App-link" onClick={(e) => handleClick('StringReversal', e)}>StringReversal</button>
        <button className="App-link" onClick={(e) => handleClick('SumOfPositiveNumbers', e)}>SumOfPositiveNumbers</button>
        <button className="App-link" onClick={(e) => handleClick('FactorialCalculator', e)}>FactorialCalculator</button>
        <button className="App-link" onClick={(e) => handleClick('VowelCounter', e)}>VowelCounter</button>
        <button className="App-link" onClick={(e) => handleClick('MaximumNumberFinder', e)}>MaximumNumberFinder</button>
        <button className="App-link" onClick={(e) => handleClick('PalindromeChecker', e)}>PalindromeChecker</button>
        <button className="App-link" onClick={(e) => handleClick('LongestWordFinder', e)}>LongestWordFinder</button>
        <button className="App-link" onClick={(e) => handleClick('RemoveDuplicatesFromArray', e)}>RemoveDuplicatesFromArray</button>
        <button className="App-link" onClick={(e) => handleClick('LeapYearChecker', e)}>LeapYearChecker</button> 
        <button className="App-link" onClick={(e) => handleClick('DataConvertor', e)}>DataConvertor</button> 
        <button className="App-link" onClick={(e) => handleClick('BinarySearchSorter', e)}>BinarySearchSorter</button> 
        <button className="App-link" onClick={(e) => handleClick('CronJobGenerator', e)}>CronJobGenerator</button> 
        <button className="App-link" onClick={(e) => handleClick('RomanNumeralConverter', e)}>RomanNumeralConverter</button> 
        <button className="App-link" onClick={(e) => handleClick('MorseCodeConvertor', e)}>MorseCodeConvertor</button> 
        <button className="App-link" onClick={(e) => handleClick('Base64Converter', e)}>Base64Converter</button>
        <button className="App-link" onClick={(e) => handleClick('VigenereCipher', e)}>VigenereCipher</button>  
        </div>
      </header>
    </div>
    
  );
}

export default App;
