import React, { useState } from 'react';

const VigenereCipher = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [key, setKey] = useState('');

  const encode = (text, key) => {
    let result = '';
    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i);
      const keyChar = key.charCodeAt(i % key.length);
      const encodedChar = String.fromCharCode(((charCode + keyChar) % 26) + 65);
      result += encodedChar;
    }
    return result;
  };

  const decode = (text, key) => {
    let result = '';
    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i);
      const keyChar = key.charCodeAt(i % key.length);
      const decodedChar = String.fromCharCode(((charCode - keyChar + 26) % 26) + 65);
      result += decodedChar;
    }
    return result;
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleKeyChange = (event) => {
    setKey(event.target.value);
  };

  const handleEncode = () => {
    const encodedText = encode(inputText.toUpperCase(), key.toUpperCase());
    setOutputText(encodedText);
  };

  const handleDecode = () => {
    const decodedText = decode(inputText.toUpperCase(), key.toUpperCase());
    setOutputText(decodedText);
  };

  return (
    <div>
      <h1>Vigenère Cipher</h1>
      <div>
        <label>
          Input Text:
          <textarea
            value={inputText}
            onChange={handleInputChange}
            placeholder="Enter text..."
            rows="5"
            cols="50"
          />
        </label>
      </div>
      <div>
        <label>
          Key:
          <input
            type="text"
            value={key}
            onChange={handleKeyChange}
            placeholder="Enter key..."
          />
        </label>
      </div>
      <div>
        <button onClick={handleEncode}>Encode</button>
        <button onClick={handleDecode}>Decode</button>
      </div>
      <div>
        <label>
          Output Text:
          <textarea
            value={outputText}
            readOnly
            placeholder="Output text..."
            rows="5"
            cols="50"
          />
        </label>
      </div>
    </div>
  );
};

export default VigenereCipher;
