import React, { useState } from 'react';

function BinarySearchSorter() {
  const [inputString, setInputString] = useState('');
  const [sortedWords, setSortedWords] = useState([]);

  const handleInputChange = (e) => {
    setInputString(e.target.value);
  };

  const binarySearch = (arr, word) => {
    let left = 0;
    let right = arr.length - 1;

    while (left <= right) {
      let mid = Math.floor((left + right) / 2);
      if (arr[mid] === word) {
        return mid;
      } else if (arr[mid] < word) {
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }
    return -1;
  };

  const sortWords = () => {
    const words = inputString.split(/\s+/).filter(word => word.trim() !== ''); // Split by whitespace and remove empty strings
    words.sort(); // Sort alphabetically

    setSortedWords(words);
  };

  return (
    <div>
      <div>
        <label>Enter a string:</label>
        <input type="text" value={inputString} onChange={handleInputChange} />
        <button onClick={sortWords}>Sort</button>
      </div>
      <div>
        <h3>Sorted Words:</h3>
        <ul>
          {sortedWords.map((word, index) => (
            <li key={index}>{word}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default BinarySearchSorter;
