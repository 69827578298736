import React, { useState } from 'react';

function SumOfPositiveNumbers() {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');

  const sumPositiveNumbers = (numbers) => {
    let sum = 0;
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i] > 0) {
        sum += numbers[i];
      }
    }
    return sum;
  };

  const calculateSum = () => {
    const numbers = input.split(',').map(Number);
    const sum = sumPositiveNumbers(numbers);
    setResult('Sum of positive numbers: ' + sum);
  };

  return (
    <div>
      <h1>Sum of Positive Numbers</h1>
      <p>Enter numbers separated by commas:</p>
      <input type="text" value={input} onChange={(e) => setInput(e.target.value)} />
      <p>
      </p>
      <button onClick={calculateSum}>Calculate Sum</button>
      <p>{result}</p>
    </div>
  );
}

export default SumOfPositiveNumbers;
