import React, { useState } from 'react';

function RomanNumeralConverter() {
  const [number, setNumber] = useState('');
  const [romanNumeral, setRomanNumeral] = useState('');

  function convertToRoman(num) {
    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1
    };

    let result = '';

    for (let key in romanNumerals) {
      while (num >= romanNumerals[key]) {
        result += key;
        num -= romanNumerals[key];
      }
    }

    return result;
  }

  function handleInputChange(event) {
    const { value } = event.target;
    setNumber(value);
    setRomanNumeral(convertToRoman(value));
  }

  return (
    <div>
      <h1>Roman Numeral Converter</h1>
      <label>
        Enter a Number:
        <input type="number" value={number} onChange={handleInputChange} />
      </label>
      <p>Roman Numeral: {romanNumeral}</p>
    </div>
  );
}

export default RomanNumeralConverter;
