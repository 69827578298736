import React, { useState } from 'react';

const Base64Converter = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isEncode, setIsEncode] = useState(true);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleConversion = () => {
    if (isEncode) {
      setOutputText(btoa(inputText));
    } else {
      setOutputText(atob(inputText));
    }
  };

  const toggleEncodeDecode = () => {
    setIsEncode(!isEncode);
  };

 return (
    <div>
      <h1>Base64 Converter</h1>
      <textarea
        value={inputText}
        onChange={handleInputChange}
        placeholder="Enter text..."
        rows="5"
        cols="50"
      />
      <div>
        <button onClick={handleConversion}>{isEncode ? 'Encode' : 'Decode'}</button>
        <button onClick={toggleEncodeDecode}>Switch to {isEncode ? 'Decode' : 'Encode'}</button>
      </div>
      <textarea
        value={outputText}
        readOnly
        placeholder="Output text..."
        rows="5"
        cols="50"
      />
    </div>
  );
};

export default Base64Converter;