import React, { useState } from 'react';

function FactorialCalculator() {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');

  const calculateFactorial = () => {
    // Convert the input value to a number
    const number = parseInt(input);
    
    // Check if the input is a valid number
    if (isNaN(number)) {
      alert("Please enter a valid number.");
      return;
    }
    
    // Check if the input is a non-negative integer
    if (number < 0 || !Number.isInteger(number)) {
      alert("Please enter a non-negative integer.");
      return;
    }
    
    // Calculate the factorial
    let factorial = 1;
    for (let i = 2; i <= number; i++) {
      factorial *= i;
    }

    // Display the factorial
    setResult(`Factorial of ${number} is ${formattedNumber(factorial)}`);
  };

  // Function to format numbers with commas
  const formattedNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div>
      <h1>Factorial Calculator</h1>
      <p>Enter a non-negative integer:</p>
      <input type="text" value={input} onChange={(e) => setInput(e.target.value)} />
      <p>
      </p>
      <button onClick={calculateFactorial}>Calculate Factorial</button>
      <p>{result}</p>
    </div>
  );
}

export default FactorialCalculator;
