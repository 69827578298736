import React, { useState } from 'react';

function DataConverter() {
  const [binary, setBinary] = useState('');
  const [decimal, setDecimal] = useState('');
  const [hexadecimal, setHexadecimal] = useState('');

  const handleBinaryChange = (e) => {
    const { value } = e.target;
    setBinary(value);
    if (/^[01]+$/.test(value)) {
      setDecimal(parseInt(value, 2).toString(10));
      setHexadecimal(parseInt(value, 2).toString(16).toUpperCase());
    } else {
      setDecimal('Invalid input');
      setHexadecimal('Invalid input');
    }
  };

  const handleDecimalChange = (e) => {
    const { value } = e.target;
    setDecimal(value);
    if (/^\d+$/.test(value)) {
      setBinary(parseInt(value, 10).toString(2));
      setHexadecimal(parseInt(value, 10).toString(16).toUpperCase());
    } else {
      setBinary('Invalid input');
      setHexadecimal('Invalid input');
    }
  };

  const handleHexadecimalChange = (e) => {
    const { value } = e.target;
    setHexadecimal(value);
    if (/^[0-9A-Fa-f]+$/.test(value)) {
      setBinary(parseInt(value, 16).toString(2));
      setDecimal(parseInt(value, 16).toString(10));
    } else {
      setBinary('Invalid input');
      setDecimal('Invalid input');
    }
  };

  return (
    <div>
      <div>
        <label>Binary: </label>
        <input type="text" value={binary} onChange={handleBinaryChange} />
      </div>
      <div>
        <label>Decimal: </label>
        <input type="text" value={decimal} onChange={handleDecimalChange} />
      </div>
      <div>
        <label>Hexadecimal: </label>
        <input type="text" value={hexadecimal} onChange={handleHexadecimalChange} />
      </div>
    </div>
  );
}

export default DataConverter;
