import React, { useState } from 'react';

const MorseCodeDictionary = {
  'A': '.-',     'B': '-...',   'C': '-.-.', 
  'D': '-..',    'E': '.',      'F': '..-.', 
  'G': '--.',    'H': '....',   'I': '..', 
  'J': '.---',   'K': '-.-',    'L': '.-..', 
  'M': '--',     'N': '-.',     'O': '---', 
  'P': '.--.',   'Q': '--.-',   'R': '.-.', 
  'S': '...',    'T': '-',      'U': '..-', 
  'V': '...-',   'W': '.--',    'X': '-..-', 
  'Y': '-.--',   'Z': '--..',
  '0': '-----', '1': '.----',  '2': '..---',
  '3': '...--', '4': '....-',  '5': '.....',
  '6': '-....', '7': '--...',  '8': '---..',
  '9': '----.',
  ' ': '/'
};

function MorseCodeConvertor() {
  const [text, setText] = useState('');
  const [morseCode, setMorseCode] = useState('');

  const handleChange = (event) => {
    const { value } = event.target;
    setText(value);
    setMorseCode(convertToMorseCode(value.toUpperCase()));
  };

  const convertToMorseCode = (text) => {
    let morseCode = '';
    for (let i = 0; i < text.length; i++) {
      const character = text[i];
      if (MorseCodeDictionary.hasOwnProperty(character)) {
        morseCode += MorseCodeDictionary[character] + ' ';
      } else {
        morseCode += ' ';
      }
    }
    return morseCode.trim();
  };

  return (
    <div>
      <h1>Alphabet to Morse Code Converter</h1>
      <label>
        Enter Text:
        <input type="text" value={text} onChange={handleChange} />
      </label>
      <p>Morse Code: {morseCode}</p>
    </div>
  );
}

export default MorseCodeConvertor;
