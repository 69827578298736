import React, { useState } from 'react';

// Custom hook for managing cron state and validation
function useCronState(initialState) {
  const [cron, setCron] = useState(initialState);
  const [errors, setErrors] = useState({});

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const getMaxDayOfMonth = (month, year) => {
    if (month === '2') {
      return isLeapYear(year) ? 29 : 28;
    } else if (['4', '6', '9', '11'].includes(month)) {
      return 30;
    } else {
      return 31;
    }
  };

  const validateField = (name, value) => {
    const num = parseInt(value, 10);
    const year = new Date().getFullYear();
    const maxDay = getMaxDayOfMonth(cron.month, year);

    const validationRules = {
      minute: { min: 0, max: 59 },
      hour: { min: 0, max: 23 },
      date: { min: 1, max: maxDay },
      month: { min: 1, max: 12 },
      day: { min: 0, max: 6 },
    };

    const rule = validationRules[name];
    if (!rule) {
      setErrors(prev => ({ ...prev, [name]: 'Invalid field' }));
      return false;
    }

    if (value !== '*' && (isNaN(num) || num < rule.min || num > rule.max)) {
      setErrors(prev => ({ ...prev, [name]: `${name} must be between ${rule.min} and ${rule.max}` }));
      return false;
    }

    setErrors(prev => ({ ...prev, [name]: null }));
    return true;
  };

  const updateCronField = (name, value) => {
    if (validateField(name, value)) {
      setCron(prev => ({ ...prev, [name]: value }));
    }
  };

  return {
    cron,
    errors,
    updateCronField,
  };
}

// Individual input field component
const CronField = ({ name, value, onFieldChange, error }) => (
  <div>
    <label>{name.charAt(0).toUpperCase() + name.slice(1)}:</label>
    <input
      type="text"
      name={name}
      value={value}
      onChange={(e) => onFieldChange(name, e.target.value)}
      style={{ width: '50px', textAlign: 'center' }}
    />
    {error && <div style={{ color: 'red' }}>{error}</div>}
  </div>
);

function CronJobGenerator() {
  const cronFields = [
    'minute',
    'hour',
    'date',
    'month',
    'day',
  ];

  const { cron, errors, updateCronField } = useCronState({
    minute: '*',
    hour: '*',
    date: '*',
    month: '*',
    day: '*',
  });

  const cronExpression = Object.values(cron).join(' ');

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial' }}>
      <h2 style={{ textAlign: 'center', color: '#333' }}>Generate Cron Job</h2>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <strong>Cron Expression:</strong> {cronExpression}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        {cronFields.map((field) => (
          <CronField
            key={field}
            name={field}
            value={cron[field]}
            onFieldChange={updateCronField}
            error={errors[field]}
          />
        ))}
      </div>
    </div>
  );
}

export default CronJobGenerator;
