import React, { useState } from 'react';

function VowelCounter() {
  // Define state for input text and vowel count
  const [inputText, setInputText] = useState('');
  const [vowelCount, setVowelCount] = useState(0);

  // Function to count vowels in the input text
  const countVowels = (str) => {
    // Define a regular expression to match vowels (case-insensitive)
    const vowelRegex = /[aeiou]/gi; // 'g' flag for global search, 'i' flag for case-insensitive search
    
    // Use match() method to find all matches of vowels in the string
    const vowelMatches = str.match(vowelRegex);
    
    // Return the number of matches (number of vowels)
    return vowelMatches ? vowelMatches.length : 0;
  };

  // Function to handle vowel counting
  const calculateVowelCount = () => {
    // Call the countVowels function and update the vowel count state
    const count = countVowels(inputText);
    setVowelCount(count);
  };

  return (
    <div>
      <h1>Vowel Counter</h1>
      <p>Enter a string:</p>
      <input
        type="text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
          <p>
      </p>
      <button onClick={calculateVowelCount}>Count Vowels</button>
      <p>Number of vowels: {vowelCount}</p>
    </div>
  );
}

export default VowelCounter;
