import React, { useState } from 'react';

function LongestWordFinder() {
  const [inputSentence, setInputSentence] = useState('');
  const [longestWord, setLongestWord] = useState('');

  // Function to find the longest word in a sentence
  const findLongestWord = (sentence) => {
    // Split the sentence into an array of words
    const words = sentence.split(" ");

    // Initialize the variable to store the longest word
    let longest = "";

    // Iterate through each word in the array
    words.forEach(word => {
      // Remove any non-alphanumeric characters from the word
      const cleanWord = word.replace(/[^a-zA-Z0-9]/g, '');

      // Check if the current word is longer than the previous longest word
      if (cleanWord.length > longest.length) {
        longest = cleanWord;
      }
    });

    return longest; // Return the longest word
  };

  // Function to handle finding the longest word in the sentence
  const findLongestWordInSentence = () => {
    // Call the findLongestWord function and get the result
    const longest = findLongestWord(inputSentence);

    // Set the state with the result
    setLongestWord(longest);
  };

  return (
    <div>
      <h1>Longest Word Finder</h1>
      <p>Enter a sentence:</p>
      <input
        type="text"
        value={inputSentence}
        onChange={(e) => setInputSentence(e.target.value)}
      />
          <p>
      </p>
      <button onClick={findLongestWordInSentence}>Find Longest Word</button>
      <p>Longest word: {longestWord}</p>
    </div>
  );
}

export default LongestWordFinder;
