import React, { useState } from 'react';

function PalindromeChecker() {
  const [inputString, setInputString] = useState('');
  const [isPalindromeResult, setIsPalindromeResult] = useState(null);

  const isPalindrome = (str) => {
    const cleanStr = str.toLowerCase().replace(/[^a-z0-9]/g, '');
    const reversedStr = cleanStr.split('').reverse().join('');
    return cleanStr === reversedStr;
  };

  const checkPalindrome = () => {
    const palindromeResult = isPalindrome(inputString);
    setIsPalindromeResult(palindromeResult);
  };

  return (
    <div>
      <h1>Palindrome Checker</h1>
      <p>Enter a string:</p>
      <input
        type="text"
        value={inputString}
        onChange={(e) => setInputString(e.target.value)}
      />
          <p>
      </p>
      <button onClick={checkPalindrome}>Check Palindrome</button>
      <p>{isPalindromeResult === null ? '' : isPalindromeResult ? 'The string is a palindrome.' : 'The string is not a palindrome.'}</p>
    </div>
  );
}

export default PalindromeChecker;
