import React, { useState } from 'react';

function MaximumNumberFinder() {
  const [inputArray, setInputArray] = useState('');
  const [maxNumber, setMaxNumber] = useState(null);

  // Function to find the maximum number
  const findMaxNumber = (arr) => {
    if (arr.length === 0) {
      return null;
    }
    let maxNumber = arr[0];
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] > maxNumber) {
        maxNumber = arr[i];
      }
    }
    return maxNumber;
  };

  // Function to handle the button click event
  const calculateMaxNumber = () => {
    const arr = inputArray.split(',').map(Number);
    const max = findMaxNumber(arr);
    setMaxNumber(max);
  };

  return (
    <div>
      <h1>Maximum Number Finder</h1>
      <p>Enter numbers separated by commas:</p>
      <input
        type="text"
        value={inputArray}
        onChange={(e) => setInputArray(e.target.value)}
      />
          <p>
      </p>
      <button onClick={calculateMaxNumber}>Find Maximum Number</button>
      <p>Maximum number: {maxNumber}</p>
    </div>
  );
}

export default MaximumNumberFinder;
